<template>
  <div class="page">
    <div class="dkzc-box">
      <!-- 标题 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/index-mockExam' }"
          >我的模拟</el-breadcrumb-item
        >
        <el-breadcrumb-item>历史记录</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="group">
        <div
          class="content"
          @click="
            $router.push({
              path: '/index-mockExam-simulationResult',
              query: { serial_code: item.serial_code, exam_id: item.id },
            })
          "
          v-for="(item, index) in dataList"
          :key="index"
        >
          <span>{{ item.create_time | timeFormat }}</span>
          <span>{{ item.name }}</span>
          <div class="time">
            <span>答对：</span>
            <span class="number">{{ item.correct_num }}</span>
            <span>答错：</span>
            <span class="number">{{ item.error_num }}</span>
            <span class="number">得分：</span>
            <span class="number">{{ item.fraction }}分</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataList: [],
    };
  },
  async mounted() {
    this.xin.isLoadding(true);
    await this.getExamHistory(this.$route.query.serial_code);
    this.xin.isLoadding(false);
  },
  methods: {
    async getExamHistory(id) {
      let res = await this.$newsApi.getExamHistory({
        title: this.title,
        serial_code: id,
      });
      if (res.code == 200) {
        this.dataList = res.data;
      } else {
        this.xin.message(res.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  background: #f2f2f2;
  > .dkzc-box {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
    > .title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-right: 10px;
    }
    > .suffix {
      margin-top: 2px;
    }
    > .group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background: #ffffff;
      padding: 30px 25px;
      box-sizing: border-box;
      justify-content: space-between; //让自动分来
      > .content {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 560px;
        height: 146px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        font-size: 24px;
        color: #333333;
        padding: 20px;
        box-sizing: border-box;
        justify-content: space-between;
        margin-top: 30px;
        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }
        > .time {
          display: flex;
          flex-direction: row;
          font-size: 14px;
          color: #8f8f8f;
          > .number {
            &:nth-child(5) {
              margin-right: 0;
            }
            color: #333333;
            margin-right: 60px;
          }
        }
      }
    }
  }
}
</style>
